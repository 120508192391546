export default translate => {
  return {
    acceptInvite: {
      modalId: 'accepted-affiliation-invite-confirmation',
      title: translate('sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.approve-invite.title'),
      subtitle: translate(
        'sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.approve-invite.subtitle'
      ),
      continueButtonText: translate(
        'sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.approve-invite.continue-button'
      ),
      cancelButtonText: translate(
        'sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.approve-invite.secondary-button'
      ),
      alertIcon: require('@/assets/images/SparkAffiliates/robot-confirm.svg'),
      continueButtonVariant: 'primary',
      cancelButtonVariant: 'outline-primary',
    },
    refuseInvite: {
      modalId: 'refused-affiliation-invite-confirmation',
      title: translate('sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.refuse-invite.title'),
      subtitle: translate('sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.refuse-invite.subtitle'),
      continueButtonText: translate(
        'sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.refuse-invite.continue-button'
      ),
      cancelButtonText: translate(
        'sparkaffiliates.affiliate-affiliation-invites-list.my-invites.modal.refuse-invite.secondary-button'
      ),
      alertIcon: require('@/assets/images/SparkAffiliates/robot-refuse.svg'),
      continueButtonVariant: 'danger',
      cancelButtonVariant: 'outline-danger',
    },
  };
};
