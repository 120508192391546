

















import { Vue, Component, Prop } from 'vue-property-decorator';
import MButton from '@/shared/components/MButton.vue';
import Card from '@/components/Card.vue';
import TextSlider from '@/components/TextSlider.vue';

@Component({
  components: { MButton, Card, TextSlider },
})
export default class AffiliationsFirstAccess extends Vue {
  @Prop({ type: Array }) deck: any[];

  goToAffiliationsList() {
    this.$router.push({ name: 'MyAffiliations' });
  }
}
