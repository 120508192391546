
















import { Vue, Component } from 'vue-property-decorator';
import PageLayout from '@/layout/PageLayout.vue';
import hsPageHeader from '@/components/_structures/PageHeader.vue';
import AffiliationsFirstAccess from './AffiliationsFirstAccess.vue';
import AffiliationsDashboard from './AffiliationsDashboard.vue';

(window as any).firstAccess = -1;

@Component({
  components: { PageLayout, hsPageHeader, AffiliationsDashboard },
})
export default class Dashboard extends Vue {
  comp = AffiliationsDashboard;

  created() {
    this.comp = ++(window as any).firstAccess ? AffiliationsDashboard : AffiliationsFirstAccess;
  }

  deck = [
    'Uma opcao interessante para que, esta iniciando nest universo de afiliacoes, e e de tentar sempre diversificar sua carteira e com isso abranger e descobrir quais as melhores oportunidades pode seguir.',
    '2 - Uma opcao interessante para que, esta iniciando nest universo de afiliacoes, e e de tentar sempre diversificar sua carteira e com isso abranger e descobrir quais as melhores oportunidades pode seguir.',
  ];
}
