<template>
  <fragment>
    <div class="tlw-px-8 tlw-pt-8 tlw-flex tlw-flex-col">
      <slot name="top" />
      <div class="tlw-flex tlw-flex-row tlw-items-center tlw-mb-6">
        <img :src="images.detailsIcon" alt="Detalhes" />
        <div class="tlw-font-semibold tlw-text-xl tlw-pl-2 tlw-mr-4 tlw-w-4/5">{{ programName }}</div>
        <div v-if="canEdit" @click="goToProgramDetails" class="sidebar-program-info__icon tlw-cursor-pointer tlw-mr-2">
          <hs-icon icon="pencil-alt" />
        </div>
        <div
          @click="deleteProgram"
          class="affiliation-details__icon affiliation-details__icon--danger tlw-cursor-pointer"
        >
          <hs-icon icon="trash-alt" />
        </div>
      </div>
      <div class="affiliation-details__details-item tlw-grid tlw-grid-cols-8 tlw-mb-2">
        <hs-icon icon="calendar-alt" />
        <div class="tlw-text-sm tlw-col-span-7">
          {{ $t('sparkaffiliates.affiliations-list.affiliation-details.affiliated-at') }}
          <strong>{{ createdAtValue }}</strong>
        </div>
      </div>
      <div class="affiliation-details__details-item tlw-grid tlw-grid-cols-8 tlw-mb-2">
        <hs-icon icon="calendar-day" />
        <div class="tlw-text-sm tlw-col-span-7">
          {{ $t('sparkaffiliates.programs-list.sidebar.expiration-date') }}
          <strong>{{ expirationDate }}</strong>
        </div>
      </div>
      <div class="affiliation-details__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <hs-icon icon="video" />
        <div class="tlw-col-span-7">
          <div class="tlw-text-sm">
            {{ $t('sparkaffiliates.programs-list.sidebar.products') }}
          </div>
          <div class="tlw-text-sm tlw-font-bold tlw-mt-1">
            <div v-for="product in activeProducts" :key="product.id">
              {{ product.productName }}
            </div>
          </div>
        </div>
      </div>
      <div class="affiliation-details__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <hs-icon icon="calendar-day" />
        <div class="tlw-text-sm tlw-col-span-7">
          <p class="tlw-m-0">Regra de comissionamento</p>
          <hs-badge variant="purple" pill>{{ displayCommissionType(affiliation.program) }}</hs-badge>
        </div>
      </div>
      <div class="affiliation-details__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <div />
        <div class="tlw-flex tlw-col-span-7">
          <MButton
            id="btnDetails"
            variant="primary"
            :label="$t('sparkaffiliates.programs-list.sidebar.see-more-affiliation')"
            @click="goToProgramDetails"
            class="tlw-font-bold tlw-text-sm tlw-mt-2"
          />
        </div>
      </div>
    </div>
    <hr class="affiliation-details__divider tlw-my-6" />

    <div class="tlw-px-8 tlw-flex tlw-flex-row tlw-gap-4">
      <div class="tlw-font-semibold tlw-text-gray-500">
        {{ $t('sparkaffiliates.affiliations-list.affiliation-details.affiliation') }}
      </div>
      <div class="program-item__card-enable tlw-flex tlw-gap-x-3 tlw-flex-row">
        {{
          affiliation.enabled
            ? $t('sparkaffiliates.affiliates-list.toast.enabled')
            : $t('sparkaffiliates.affiliates-list.toast.disabled')
        }}
        <MSwitch
          @change="updateAffiliationStatus"
          :id="`toggle-sidebar-${affiliation.id}`"
          v-model="affiliation.enabled"
        />
      </div>
    </div>
    -
    <hr class="affiliation-details__divider tlw-my-6" />
    <div class="tlw-px-8 tlw-font-semibold tlw-text-gray-500">
      {{ $t('sparkaffiliates.affiliations-list.drawer.product-links') }}
    </div>
    <div class="tlw-px-8 tlw-flex tlw-flex-row tlw-pt-4 tlw-gap-4">
      <hs-icon icon="video" variant="regular" size="16" />
      <div class="tlw-flex tlw-flex-col">
        <div class="tlw-pb-4" v-for="product in activeProducts" :key="product.productId">
          <div class="tlw-text-base tlw-font-bold tlw-pb-1">
            {{ product.productName }}
          </div>
          <div
            v-for="(link, index) in product.links"
            :key="link.id"
            class="tlw-flex tlw-flex-row tlw-gap-2 tlw-items-center"
          >
            <hs-icon class="clone-icon font-weight-light" variant="regular" icon="clone" size="16" />
            <div
              class="tlw-text-sm tlw-mb-2 tlw-cursor-pointer tlw-text-blue-900 tlw-underline"
              @click="copyUrlToClipboard(link.referral_link)"
            >
              Link para checkout {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import ToastHelper from '@/shared/helpers/toast';
import { mapActions } from 'vuex';
import tracking from '@/shared/helpers/tracking';
import { displayCommissionType } from '@/libs/program';
import MButton from '@/shared/components/MButton.vue';
import MSwitch from '@/shared/components/MSwitch.vue';

export default {
  name: 'AffiliationDetails',
  components: {
    MButton,
    MSwitch,
  },
  data() {
    return {
      images: {
        detailsIcon: require('@/assets/images/SparkAffiliates/Programs/details.svg'),
      },
      activeProducts: [],
      offerList: [],
    };
  },
  props: {
    createdAtValue: {
      type: String,
      required: true,
    },
    getActiveProducts: {
      type: Function,
      required: true,
    },
    affiliation: {
      type: Object,
      required: true,
    },
    sidebarId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    if (_.isEmpty(this.program)) {
      return;
    }
    await this.fetchProductData();
  },
  computed: {
    expirationDate() {
      const expirationDate = _.get(this.affiliation.program, 'expiration_date', null);
      return expirationDate ? dayjs(this.affiliation.program.expiration_date).format('DD/MM/YYYY') : 'Sem expiração';
    },
    programName() {
      return _.get(this.affiliation.program, 'name', null);
    },
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOptions']),
    displayCommissionType(program) {
      return displayCommissionType(_.get(program, 'commission_type', ''));
    },
    goToProgramDetails() {
      this.$emit('goToProgramDetails', this.affiliation.program);
    },
    deleteProgram() {
      this.$emit('delete', this.affiliation);
    },
    async updateAffiliationStatus(status) {
      this.$emit('toggle-activation', {
        status,
        affiliation: this.affiliation,
      });
    },
    async fetchProductData() {
      this.activeProducts = await this.getActiveProducts(this.affiliation.program).then(response => {
        if (!response) return [];

        return response.map(product => {
          let links = this.affiliation.links.filter(link => product.id === link.sparkecommerce_product_id);

          return {
            productId: product.id,
            productName: product.name,
            links,
          };
        });
      });
    },
    async copyUrlToClipboard(url) {
      try {
        await navigator.clipboard.writeText(url);
        tracking.affiliationOfferLinkCopied();
        ToastHelper.successMessage('Link copiado com sucesso!');
      } catch (error) {
        ToastHelper.dangerMessage('Ocorreu um erro ao copiar seu link.');
      }
    },
  },
  watch: {
    affiliation() {
      this.fetchProductData();
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliation-details__icon {
  color: $cyan;
  height: fit-content;
}
.affiliation-details__icon--danger {
  color: $cherry;
}

.affiliation-details__details-item {
  align-items: baseline;
}

.affiliation-details__divider {
  border: 1px solid $grey-22;
}
</style>
