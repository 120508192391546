<template>
  <page-tab-layout tab-class="affiliations-list__tab" :current-tab="currentTab" :tabs="tabs" @change-tab="changeTab">
    <template v-slot:header>
      <NavbarBreadcrumb class="tlw-flex tlw-mr-auto bredcrumb-mobile d-md-none" />
      <hsPageHeader
        :title="$t('sparkaffiliates.affiliations-list.header.title')"
        :sub-title="$t('sparkaffiliates.affiliations-list.header.subtitle')"
        :back-text="$t('sparkaffiliates.affiliations-list.header.backtext')"
        back-route="MyAffiliations"
      />
    </template>
    <div class="p-4 md:tlw-p-10">
      <component v-if="!isLoading" :is="currentTab" :can-edit="false" :program="program" />
      <hsLoading v-else />
    </div>
  </page-tab-layout>
</template>

<script>
import debug from 'debug';
import ToastHelper from '@/shared/helpers/toast';
import PageTabLayout from '@/layout/PageTabLayout.vue';
import { hsLoading } from '@/components';
import hsPageHeader from '@/components/_structures/PageHeader';
import ProgramDetailsEditor from '@/sparkaffiliates/components/ProgramDetailsEditor';
import programService from '@/sparkaffiliates/services/programs';
import NavbarBreadcrumb from '@//views/MenuHeaderLayout/Navbar/NavbarBreadcrumb.vue';
import AffiliationPixel from '@/sparkaffiliates/views/AffiliationPixel.vue';

const logging = debug('hs:program');

export default {
  name: 'Program',
  components: {
    PageTabLayout,
    hsLoading,
    hsPageHeader,
    ProgramDetailsEditor,
    NavbarBreadcrumb,
    AffiliationPixel,
  },
  data() {
    return {
      isLoading: true,
      program: {},
      tabs: [
        {
          id: ProgramDetailsEditor.name,
          label: 'Resumo de Programa',
          component: ProgramDetailsEditor.name,
          permission: false,
        },
        {
          id: AffiliationPixel.name,
          label: 'Pixel de Rastreamento',
          component: AffiliationPixel.name,
          permission: false,
        },
      ],
      currentTab: ProgramDetailsEditor.name,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        const { data } = await programService.getAffiliateProgram(this.$route.params.id);
        this.program = data;
      } catch (e) {
        logging('failed to load affiliates program.', e);
        ToastHelper.dangerMessage('Falha ao carregar o programa da afiliação.');
      } finally {
        this.isLoading = false;
      }
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.bredcrumb-mobile {
  background: white;
  padding: 30px 18px;
  border-bottom: 1px solid #e3e3e3;
}
/deep/.breadcrumb li:first-child {
  display: none;
}
/deep/ .breadcrumb li:nth-child(3n) {
  display: none;
}
</style>
