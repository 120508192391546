<template>
  <fragment>
    <div class="tlw-px-8 tlw-pt-8 tlw-flex tlw-flex-col">
      <slot name="top" />
      <div class="tlw-flex tlw-flex-row tlw-items-center tlw-mb-6">
        <img :src="images.detailsIcon" alt="Detalhes" />
        <div class="tlw-font-semibold tlw-text-xl tlw-pl-2 tlw-mr-4 tlw-w-4/5">{{ invite.program_name }}</div>
        <div class="envelope-icon tlw-cursor-pointer tlw-mr-2" @click="sendEmail">
          <hs-icon icon="envelope" />
        </div>
        <div @click="deleteInvite" class="trash-icon tlw-cursor-pointer">
          <hs-icon icon="trash-alt" />
        </div>
      </div>
      <div class="tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <hs-icon class="tlw-flex-1" icon="calendar-alt" />
        <div class="tlw-text-sm tlw-flex-auto tlw-col-span-7">
          Convite de afiliação em:
          <date-formatter use-component="strong" :date="invite.created_at" format="DD/MM/YYYY" default-value="" />
        </div>
        <hs-icon class="tlw-flex-1" icon="calendar-day" />
        <div class="tlw-text-sm tlw-flex-auto tlw-col-span-7">
          {{ $t('sparkaffiliates.programs-list.sidebar.expiration-date') }}
          <date-formatter
            use-component="strong"
            :date="invite.expiration_date"
            format="DD/MM/YYYY"
            default-value="Sem data de expiração"
          />
        </div>
        <hs-icon class="tlw-flex-1 tlw-self-start" icon="video" />
        <div class="tlw-flex tlw-flex-col tlw-col-span-7">
          <div class="tlw-text-sm tlw-flex-auto">
            Programa vinculado
          </div>
          <div id="products-list" class="tlw-text-sm tlw-font-bold tlw-mt-1">
            <div v-for="product in products" :key="product.id">
              {{ product }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="divider tlw-my-6" />
    <div class="tlw-px-8 tlw-flex tlw-flex-row">
      <div class="tlw-font-semibold tlw-text-gray-500">
        Afiliação:
      </div>
      <div id="affliation-buttons" class="tlw-ml-4">
        <hs-button variant="primary" class="tlw-w-full tlw-mb-4" @click="accept">Aprovar convite</hs-button>
        <hs-button variant="outline-primary" class="tlw-w-full" @click="decline">Reprovar convite</hs-button>
      </div>
    </div>
    <hr class="divider tlw-my-6" />
  </fragment>
</template>

<script>
import DateFormatter from '@/components/DateFormatter.vue';
import { AffiliationInvitesStatus } from '@/types/affiliates';

export default {
  name: 'AffiliationInvite',
  components: { DateFormatter },
  props: {
    invite: {
      type: Object,
      required: true,
    },
    getActiveProductsName: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      images: {
        detailsIcon: require('@/assets/images/SparkAffiliates/Programs/details.svg'),
      },
      products: [],
    };
  },
  mounted() {
    this.getProductsName();
  },
  methods: {
    goToProgramEdit() {},
    updateStatus(status, invite) {
      this.$emit('update-status', { status, invite });
    },
    accept() {
      this.updateStatus(AffiliationInvitesStatus.ACCEPTED, this.invite);
    },
    decline() {
      this.updateStatus(AffiliationInvitesStatus.REFUSED, this.invite);
    },
    deleteInvite() {
      this.$emit('delete', this.invite);
    },
    sendEmail() {
      this.$emit('send-email', this.invite);
    },
    async getProductsName() {
      this.products = await this.getActiveProductsName(this.invite);
    },
  },
  watch: {
    invite() {
      this.getProductsName();
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 24px 32px;
}

.section-title {
  color: $grey-ba;
}

.activation-section {
  border-top: 1px solid $grey-ba;
  border-bottom: 1px solid $grey-ba;
}

.image {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: $purple-dark;
}

.badge {
  padding: 4px 6px;
  background-color: $purple-dark;
  color: $white;
  border-radius: 50px;
  font-size: 12px;
}

.envelope-icon {
  color: $blueish-dark;
  cursor: pointer;
}
.trash-icon {
  color: $redish-dark;
  cursor: pointer;
}
</style>
