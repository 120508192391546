<template>
  <hs-table :fields="fields" select-mode="multi" :selectable="false" :responsive="true" :items="affiliationInvites">
    <template slot="programs" slot-scope="row">
      <span v-if="!Boolean(row.item.program_name)">-</span>
      <div v-else>
        <span class="line-programs">
          {{ row.item.program_name }}
        </span>
      </div>
    </template>

    <template slot="commissions" slot-scope="row">
      <span v-if="!Boolean(row.item.commissions)">-</span>
      <div v-else>
        <hs-badge
          v-for="(commission, index) in row.item.commissions"
          :key="`commission-badge-${index}`"
          pill
          variant="purple"
        >
          Comissão {{ commission }}%
        </hs-badge>
      </div>
    </template>

    <template slot="status" slot-scope="row">
      <hs-badge v-if="row.item.status !== AffiliationInvitesStatus.PENDING" pill variant="warning">
        {{ inviteStatusDictionary[row.item.status] || '-' }}
      </hs-badge>
      <div v-else>
        <hs-button class="tlw-mr-4" variant="primary" @click="updateStatus(row.item.id, 'accepted')">
          {{ $t('sparkaffiliates.affiliate-affiliation-invites-list.my-invites.status-update.approve') }}
        </hs-button>
        <hs-button variant="outline-primary" @click="updateStatus(row.item.id, 'refused')">
          {{ $t('sparkaffiliates.affiliate-affiliation-invites-list.my-invites.status-update.refuse') }}
        </hs-button>
      </div>
    </template>

    <template slot="action" slot-scope="row">
      <div class="tlw-cursor-pointer" @click="showInvite(row.item)">
        <hs-icon icon="chevron-right" class="tlw-py-6 tlw-pl-6 line-arrow" />
      </div>
    </template>
  </hs-table>
</template>

<script>
import { AffiliationInvitesStatus } from '@/types/affiliates';
import { inviteStatusDictionary } from '@/libs/affiliates';

const makeField = (key, label, extra = {}) => ({
  key,
  label,
  ...extra,
});

const fields = translate => {
  const sortable = { sortable: true };
  return [
    makeField('check', '', { thClass: 'th-checkbox' }),
    makeField('programs', translate('Nome do programa'), sortable),
    makeField('commissions', translate('Comissões oferecidas'), sortable),
    makeField('status', translate('Status do convite'), sortable),
    makeField('action', ''),
  ];
};

export default {
  name: 'PendingInvitesList',
  props: {
    affiliationInvites: { type: Array },
  },
  data() {
    return {
      fields: fields(key => this.$t(key)),
      inviteStatusDictionary,
      AffiliationInvitesStatus,
    };
  },
  methods: {
    updateStatus(id, status) {
      this.$emit('update-invite-status', { id, status });
    },
    showInvite(invite) {
      this.$emit('show-invite', invite);
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliation-invites-list {
  th {
    color: $grey-ba;
    text-decoration: uppercase;
  }
}

.line {
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);

  td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &-checkbox {
    margin: 28px 0 28px 32px;
  }

  &-programs {
    color: $grey;
    font-size: 14px;
  }

  &-arrow {
    color: $grey-ba;
  }
}
</style>
