

















import { Tab } from '@/types/tab';
import { Vue, Component } from 'vue-property-decorator';
import PageTabLayout from '@/layout/PageTabLayout.vue';
import hsPageHeader from '@/components/_structures/PageHeader.vue';
import AffiliationsListTab from '@/sparkaffiliates/views/tabs/AffiliationsListTab.vue';
import AffiliationInvitesTab from '@/sparkaffiliates/views/tabs/InvitesListTab.vue';
import NavbarBreadcrumb from '@/views/MenuHeaderLayout/Navbar/NavbarBreadcrumb.vue';

@Component({
  components: {
    PageTabLayout,
    hsPageHeader,
    AffiliationsListTab,
    AffiliationInvitesTab,
    NavbarBreadcrumb,
  },
})
export default class AffiliationsList extends Vue {
  tabs: Tab[] = [];

  currentTab = AffiliationsListTab.name;

  created() {
    this.tabs = [
      {
        id: AffiliationsListTab.name,
        component: AffiliationsListTab.name,
        label: this.$t('sparkaffiliates.affiliations.tabs.my-affiliations'),
        permission: false,
      },
      {
        id: AffiliationInvitesTab.name,
        component: AffiliationInvitesTab.name,
        label: this.$t('sparkaffiliates.affiliations.tabs.my-invites'),
        permission: false,
      },
    ];
  }

  changeTab(tab: string) {
    this.currentTab = tab;
  }
}
