<template>
  <div class="empty-program__container tlw-flex tlw-justify-center">
    <div class="empty-program__content tlw-grid tlw-self-center tlw-justify-items-center tlw-text-center">
      <img class="tlw-pb-10" :src="images.addProgramIcon" />
      <div class="empty-program__title tlw-font-bold tlw-text-2xl tlw-pb-4" v-html="title" />
      <div class="empty-program__subtitle tlw-pb-6 px-5" v-html="subtitle" />
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmptyList',
  data() {
    return {
      images: {
        addProgramIcon: require('@/assets/images/SparkAffiliates/affiliates/robot-frame.svg'),
      },
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-program__content {
  width: 408px;
}
</style>
