<template>
  <hsLoading v-if="isLoading" />
  <div v-else>
    <EmptyList
      v-if="!hasFilters && !hasAffiliationInvites"
      :title="$t('sparkaffiliates.affiliate-affiliation-invites-list.my-invites.empty-list.title')"
      :subtitle="$t('sparkaffiliates.affiliate-affiliation-invites-list.my-invites.empty-list.subtitle')"
    />
    <div v-else>
      <program-filter
        :nameSearchTerm="nameSearchTerm"
        :sortOptions="sortOptions"
        :sortByValue="sortByValue"
        :programOptions="programOptions"
        :filterByProgram="filterByProgram"
        @input-search-term="filterByText"
        @selected-ordering="filterBySelectedOption"
        @selected-program="filterBySelectedProgram"
        @clear-filter="clearFilter"
        class="pb-5"
      />

      <pending-invites-list
        :affiliation-invites="affiliationInvites"
        @update-invite-status="openUpdateStatusModalConfirmation"
        @show-invite="showInviteInformation"
      />

      <hs-pagination
        v-if="pagination.totalCount > pagination.perPage"
        v-model="pagination.currentPage"
        :go-page-text="$t('sparkmembers.coupons.views.list.index.pagination.go-to-page')"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalCount"
        align="center"
      />

      <right-drawer :is-open="hasSelectedInvite" @close="closeDrawer">
        <affiliation-invite
          :invite="currentInvite"
          :getActiveProductsName="getActiveProductsName"
          @update-status="updateStatus"
          @delete="deleteInvite"
          @send-email="sendEmail"
        >
          <template v-slot:top>
            <div class="tlw-flex tlw-flex-wrap tlw-mb-4">
              <hs-badge pill class="tlw-w-min tlw-mr-2" variant="yellow">{{ inviteStatusText }}</hs-badge>
              <hs-badge
                v-for="(commission, index) in currentInvite.commissions"
                :key="`details-commission-badge-${index}`"
                pill
                class="tlw-w-min tlw-mr-2"
                variant="purple"
              >
                Comissão {{ commission }}%
              </hs-badge>
            </div>
          </template>
        </affiliation-invite>
      </right-drawer>
    </div>

    <ConfirmationModal v-bind="modalData" @confirmOperation="updateInviteStatus(selectedInviteToUpdate)" />
  </div>
</template>

<script>
import debug from 'debug';
import _ from 'lodash';
import { hsLoading } from '@/components';
import LoadingMixin from '@/shared/mixins/LoadingMixin';
import PaginationMixin from '@/sparkaffiliates/mixins/PaginationMixin';
import ProgramFilterMixin from '@/sparkaffiliates/mixins/ProgramFilterMixin';
import ToastHelper from '@/shared/helpers/toast';
import affiliationInviteService from '@/sparkaffiliates/services/affiliation_invite.js';
import EmptyList from '@/sparkaffiliates/components/EmptyList.vue';
import PendingInvitesList from '@/sparkaffiliates/components/PendingInvitesList.vue';
import { MY_AFFILIATION_INVITES_TAB } from '@/libs/affiliates';
import ProgramFilter from '@/sparkaffiliates/views/Producer/components/ProgramFilter.vue';
import productService from '@/sparkmembers/services/product.js';
import programsService from '@/sparkaffiliates/services/programs';
import ConfirmationModal from '@/sparkaffiliates/views/Producer/ProgramPage/components/shared/ConfirmationModal';
import affiliationInviteModalData from '@/data/affiliation_invite_modal';
import { AffiliationInvitesStatus } from '@/types/affiliates';
import AffiliationInvite from '@/sparkaffiliates/components/AffiliationInvite.vue';
import RightDrawer from '@/components/RightDrawer.vue';

const logging = debug('hs:affiliation-invites');

export default {
  name: MY_AFFILIATION_INVITES_TAB,
  mixins: [LoadingMixin, ProgramFilterMixin, PaginationMixin],
  components: {
    hsLoading,
    ProgramFilter,
    PendingInvitesList,
    EmptyList,
    ConfirmationModal,
    RightDrawer,
    AffiliationInvite,
  },
  data() {
    return {
      isLoading: false,
      affiliationInvites: [],
      programs: [],
      defaultSearchParams: {
        status: ['pending'],
      },
      selectedInviteToUpdate: {},
      affiliationInviteModalData: affiliationInviteModalData(key => this.$t(key)),
      inviteId: null,
    };
  },
  created() {
    this.initiateProgramFilter(this.updateAffiliationInvitesFromFilter);
    this.initiatePagination(this.updateAffiliationInvitesFromPagination);
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    inviteStatusText() {
      if (!this.hasSelectedInvite) {
        return '';
      }

      const table = {
        [AffiliationInvitesStatus.PENDING]: this.$t('aguardando aprovação'),
        [AffiliationInvitesStatus.ACCEPTED]: this.$t('aprovado'),
        [AffiliationInvitesStatus.EXPIRED]: this.$t('expirado'),
        [AffiliationInvitesStatus.REFUSED]: this.$t('recusado'),
      };
      return table[this.currentInvite.status];
    },
    countAffiliationInvites() {
      return this.pagination.totalCount;
    },
    hasAffiliationInvites() {
      return this.pagination.totalCount > 0;
    },
    hasSelectedInvite() {
      return Boolean(this.inviteId);
    },
    currentInvite() {
      return this.affiliationInvites.find(({ id }) => id === this.inviteId) || {};
    },
    isApprovingInvite() {
      return this.selectedInviteToUpdate.status === AffiliationInvitesStatus.ACCEPTED;
    },
    modalData() {
      const { acceptInvite, refuseInvite } = this.affiliationInviteModalData;
      return this.isApprovingInvite ? acceptInvite : refuseInvite;
    },
  },
  methods: {
    async getActiveProductsName(invite) {
      try {
        if (_.isEmpty(invite)) {
          return Promise.resolve([]);
        }

        const program = this.programs.find(({ id }) => id === invite.program_id);

        const activeProducts = program.program_products.map(product =>
          productService.getName(product.sparkecommerce_product_id).then(({ title }) => title)
        );

        return await Promise.all(activeProducts);
      } catch (e) {
        const message = 'Ocorreu algum problema ao carregar produtos';
        logging(message, e);
        ToastHelper.dangerMessage(message);
      }
    },
    updateStatus({ status, invite }) {
      return this.updateInviteStatus({ status, id: invite.id });
    },
    sendEmail() {
      logging('trying to send an e-mail to affiliate', affiliation);
    },
    deleteInvite(invite) {
      // NOTE: for now, we are going to refuse the invite
      this.updateStatus({
        status: AffiliationInvitesStatus.REFUSED,
        invite,
      }).then(() => {
        this.inviteId = null;
      });
    },
    clearFilter() {
      this.clearProgramFilter();
      this.updateAffiliationInvitesFromFilter();
    },
    onFailedLoading() {
      ToastHelper.dangerMessage(this.$t('Ocorreu algum problema ao listar os seus convites de afiliação'));
    },
    updateAffiliationInvitesFromFilter() {
      this.loadAffiliationInvites({
        ...this.searchParams,
        ...this.defaultSearchParams,
      }).catch(this.onFailedLoading);
    },
    updateAffiliationInvitesFromPagination() {
      this.loadAffiliationInvites({
        ...this.searchParams,
        ...this.defaultSearchParams,
        page: this.pagination.currentPage,
      }).catch(this.onFailedLoading);
    },
    showInviteInformation({ id }) {
      this.inviteId = id;
    },
    closeDrawer() {
      this.inviteId = null;
    },
    async openUpdateStatusModalConfirmation(params) {
      this.selectedInviteToUpdate = params;

      await this.$nextTick();
      this.$bvModal.show(this.modalData.modalId);
    },
    async updateInviteStatus({ id, status }) {
      await affiliationInviteService.updateAffiliateAffiliationInviteStatus(id, status);

      this.updateAffiliationInvitesFromFilter();
      this.selectedInviteToUpdate = {};
    },
    loadAffiliationInvites(params = {}) {
      return this.withLoading(async () => {
        const { affiliationInvites, ...pagination } = await affiliationInviteService.getAffiliateAffiliationInvites(
          params
        );
        this.affiliationInvites = affiliationInvites;
        this.pagination = pagination;

        logging('loaded %d affilation invites', affiliationInvites.length, affiliationInvites);
      });
    },
    updateProgramsFilter() {
      this.programOptions = this.programs.map(program => ({
        id: program.id,
        title: program.name,
      }));
    },
    async loadPrograms(params = {}) {
      const { programs } = await programsService.getAffiliatePrograms(params);
      this.programs = programs;
      logging('loaded %d programs', programs.length, programs);
      this.updateProgramsFilter();
    },
    async fetchData() {
      this.loadAffiliationInvites(this.defaultSearchParams)
        .then(() => this.loadPrograms({ items: 50 }))
        .catch(this.onFailedLoading);
    },
  },
  watch: {
    'pagination.currentPage': function(nextValue, oldValue) {
      nextValue !== oldValue && this.updateAffiliationInvitesFromPagination();
    },
  },
};
</script>

<style lang="scss">
.badge-yellow {
  color: $yellow-darker;
  background-color: $yellow-light-03;
}
</style>
