<template>
  <div class="affiliation-pixel">
    <h4 class="tlw-mt-4 tlw-font-bold title">
      {{ $t('sparkmembers.offers.affiliation-pixel-form.title') }}
    </h4>

    <div class="tlw-mt-4 tlw-font-bold tlw-text-base">
      {{ $t('sparkmembers.offers.offer-wizard.step-three.title') }}
    </div>
    <div class="tlw-text-sm tlw-font-normal tlw-text-gray-700 tlw-mb-5">
      {{ $t('sparkmembers.offers.offer-wizard.step-three.subtitle') }}
    </div>

    <h4 class="tlw-mb-5 tlw-mt-4 tlw-font-bold tlw-text-base">
      {{ $t('sparkmembers.offers.offer-wizard.step-three.facebook-label') }}
      <span class="tlw-text-gray-700 tlw-font-normal tlw-text-xs tlw-ml-2">
        {{ $t('sparkmembers.offers.offer-wizard.step-three.optional-label') }}
      </span>
    </h4>
    <div class="affiliation-pixel-form">
      <hs-group class="tlw-mb-2">
        <MSwitch
          v-model="displayFacebookTracking"
          :label-title="$t('sparkmembers.offers.affiliation-pixel-form.enable-facebook')"
          :label-description="$t('sparkmembers.offers.affiliation-pixel-form.facebook-description')"
        />
        <MButton
          v-if="!displayFacebookTracking"
          class="mt-4 w-100 d-md-none"
          variant="primary"
          @click="submit"
          disabled
        >
          {{ $t('sparkmembers.offers.tabs.form.btn-save') }}
        </MButton>

        <div class="tlw-mt-3.5" v-if="displayFacebookTracking">
          <hs-group>
            <label for="facebook_pixel" class="font-weight-bold">
              {{ $t('sparkmembers.offers.affiliation-pixel-form.facebook-pixel-label') }}
            </label>
            <hs-input
              name="facebook_pixel"
              id="facebook_pixel"
              type="number"
              v-model="facebookPixel"
              :disabled="isLoading"
              :placeholder="$t('sparkmembers.offers.affiliation-pixel-form.facebook-pixel-placeholder')"
            />
            <a
              :href="$t('sparkmembers.offers.affiliation-pixel-form.facebook-pixel-helper-link')"
              target="_blank"
              class="affiliation-pixel-form__helper-link"
            >
              {{ $t('sparkmembers.offers.affiliation-pixel-form.facebook-pixel-helper') }}
            </a>
          </hs-group>

          <hs-group>
            <label for="facebook_conversion_api" class="font-weight-bold">
              {{ $t('sparkmembers.offers.affiliation-pixel-form.conversion-api-token-label') }}
            </label>
            <hs-input
              name="facebook_conversion_api"
              id="facebook_conversion_api"
              type="text"
              v-model="facebookApiToken"
              :disabled="isLoading"
              :placeholder="$t('sparkmembers.offers.affiliation-pixel-form.conversion-api-token-placeholder')"
            />
            <a
              :href="$t('sparkmembers.offers.affiliation-pixel-form.facebook-api-token-helper-link')"
              target="_blank"
              class="affiliation-pixel-form__helper-link"
            >
              {{ $t('sparkmembers.offers.affiliation-pixel-form.facebook-api-token-helper') }}
            </a>
          </hs-group>
        </div>
        <div v-if="displayFacebookTracking" class="offer-wizard-actions tlw-mt-6 tlw-text-right mobile-actions">
          <hs-button variant="outline" @click="$bvModal.show('cancel-confirmation-modal')" class="btn-cancel">
            {{ $t('sparkmembers.offers.tabs.form.btn-cancel') }}
          </hs-button>
          <hs-button variant="purple" @click="submit" class="btn-save">
            {{ $t('sparkmembers.offers.tabs.form.btn-save') }}
          </hs-button>
        </div>
      </hs-group>
    </div>
    <b-modal
      modalClass="coupon-creator-modal"
      id="cancel-confirmation-modal"
      :scrollable="false"
      hide-header
      hide-footer
      centered
    >
      <div>
        <button type="button" class="tlw-block tlw-ml-auto" @click="closeModal">
          <hs-icon icon="times" :size="25" />
        </button>
        <div class="tlw-grid tlw-mx-auto tlw-justify-center tlw-text-center">
          <div class="tlw-mx-auto tlw-mb-3 md:tlw-my-8">
            <img src="@/assets/images/Coproduction/robots/robot-critical.svg" alt="delete_robot" />
          </div>
          <h3
            class="tlw-text-2xl	mb-4 font-weight-bold text-center"
            v-html="$t('sparkmembers.offers.affiliation-pixel-form.title-modal')"
          />
          <p
            class="tlw-text-base mx-4 tlw-mb-10"
            v-html="$t('sparkmembers.offers.affiliation-pixel-form.subtitle-modal')"
          />
          <div class="modal-grid mx-4 mb-2">
            <MButton
              variant="outline"
              size="md"
              class="tlw-w-full btn-cancel-modal"
              :label="$t('sparkmembers.offers.affiliation-pixel-form.btn-cancel-modal')"
              @click="cancel"
            />
            <MButton
              variant="purple"
              class="tlw-w-full tlw-w-full btn-save-modal"
              style="margin-top: 10px"
              :label="$t('sparkmembers.offers.affiliation-pixel-form.btn-continue-modal')"
              @click="closeModal"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MSwitch from '@/shared/components/MSwitch.vue';
import programService from '@/sparkaffiliates/services/programs';
import affiliationsService from '@/sparkaffiliates/services/affiliations';
import ToastHelper from '@/shared/helpers/toast';
import tracking from '@/shared/helpers/tracking';
import MButton from '@/shared/components/MButton.vue';

export default {
  name: 'AffiliationPixel',
  components: {
    MSwitch,
    MButton,
  },
  data() {
    return {
      displayFacebookTracking: false,
      facebookPixel: '',
      facebookApiToken: '',
      isLoading: false,
    };
  },
  methods: {
    trackingData() {
      return {
        facebook_pixel: this.facebookPixel,
        facebook_api_token: this.facebookApiToken,
      };
    },
    closeModal() {
      this.$bvModal.hide('cancel-confirmation-modal');
    },
    async submit() {
      try {
        this.isLoading = true;
        await programService.updateFacebookTrackingFields(this.$route.params.id, this.trackingData());
        if (this.facebookPixel || this.facebookApiToken) {
          tracking.affiliateFacebookPixelAdded(this.$store.state.school.selectedSchool.id);
        }
        ToastHelper.successMessage(this.$t('sparkaffiliates.affiliation-tracking.toast.success'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkaffiliates.affiliation-tracking.toast.error'));
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.facebookPixel = this.facebookApiToken = '';
      this.closeModal();
    },
    async carregar(program_id) {
      const payload = {
        program_id,
        email: this.$store.state.school.selectedSchool.email,
      };
      const { data } = await affiliationsService.getFacebookTracking(payload);
      this.facebookPixel = data.facebook_pixel;
      this.facebookApiToken = data.facebook_api_token;
      this.displayFacebookTracking = !!this.facebookPixel || !!this.facebookApiToken;
    },
  },
  mounted() {
    this.carregar(this.$route.params.id);
  },
};
</script>

<style lang="scss">
.affiliation-pixel-form__helper-link {
  color: #418cfc;
  font-size: 0.75rem;
  text-decoration: underline;
  &:hover {
    color: #1e4278;
  }
}
.affiliation-pixel {
  background: white;
  padding: 15px 32px 32px 32px;
  .btn-cancel {
    color: #7427f1;
    border-radius: 4px;
    border: 2px solid #7427f1;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
  }
  .btn-save {
    padding: 8px 16px;
    margin-left: 10px;
  }
}
.btn-save-modal {
  background: #7427f1;
  color: white;
  padding: 12px 20px;
}
.btn-cancel-modal {
  color: #7427f1;
  border-radius: 4px;
  border: 2px solid #7427f1;
}
.offer-wizard-actions {
  @media (max-width: $screen-sm) {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    .btn-save {
      margin-left: 0;
      flex: 0;
    }
  }
}
</style>
