<template>
  <hsLoading v-if="isLoading" />
  <div v-else>
    <EmptyList
      class="mt-4"
      v-if="!hasFilters && !hasAffiliations"
      :title="$t('sparkaffiliates.affiliates-list.empty-list.title')"
      :subtitle="$t('sparkaffiliates.affiliates-list.empty-list.subtitle')"
    >
    </EmptyList>
    <fragment v-else>
      <program-filter
        :nameSearchTerm="nameSearchTerm"
        :sortOptions="sortOptions"
        :sortByValue="sortByValue"
        :programOptions="programOptions"
        :filterByProgram="filterByProgram"
        @input-search-term="filterByText"
        @selected-ordering="filterBySelectedOption"
        @selected-program="filterBySelectedProgram"
        @clear-filter="clearFilter"
        class="pb-5"
      />

      <affiliations-list
        :affiliations="affiliations"
        @show-affiliation="showAffiliationInformation"
        @toggle-activation="toggleActivation"
      />

      <right-drawer :is-open="hasSelectedAffiliation" @close="closeDrawer">
        <affiliation-details
          sidebar-id="program-info"
          :can-edit="false"
          :affiliation="currentAffiliation"
          :getActiveProducts="getActiveProducts"
          :created-at-value="affiliationCreatedAt"
          @goToProgramDetails="goToProgramDetails"
          @toggle-activation="toggleActivation"
          @delete="showDeletionConfirmationModal"
        >
          <template v-slot:top>
            <hs-badge :key="`details-commission-badge-${index}`" pill class="tlw-w-min tlw-mb-4" variant="purple">
              Comissões de até {{ Math.max(...currentAffiliation.commissions) }}%
            </hs-badge>
          </template>
        </affiliation-details>
      </right-drawer>

      <hs-pagination
        v-if="pagination.totalCount > pagination.perPage"
        v-model="pagination.currentPage"
        :go-page-text="$t('sparkmembers.coupons.views.list.index.pagination.go-to-page')"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalCount"
        align="center"
        class="d-none d-md-block"
      />
    </fragment>

    <MConfirmModal
      id="confirm-modal"
      variant="primary"
      :image="require('@/assets/images/SparkAffiliates/affiliates/robot-user-minus.svg')"
      @confirm="deleteDrawerAffiliation"
      @cancel="closeConfirmationModal"
      :title="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.title')"
      :text="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.subtitle')"
      :confirmButtonLabel="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.continue-button')"
      :cancelButtonLabel="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.secondary-button')"
    />
  </div>
</template>

<script>
import debug from 'debug';
import _ from 'lodash';
import dayjs from 'dayjs';
import { productService } from '@/services';
import { hsLoading } from '@/components';
import LoadingMixin from '@/shared/mixins/LoadingMixin';
import ProgramFilterMixin from '@/sparkaffiliates/mixins/ProgramFilterMixin';
import PaginationMixin from '@/sparkaffiliates/mixins/PaginationMixin';
import ToastHelper from '@/shared/helpers/toast';
import AffiliationsList from '@/sparkaffiliates/views/AffiliationsList.vue';
import ProgramFilter from '../Producer/components/ProgramFilter.vue';
import EmptyList from '@/sparkaffiliates/components/EmptyList.vue';
import affiliationsService from '@/sparkaffiliates/services/affiliations';
import programsService from '@/sparkaffiliates/services/programs';
import programProductsService from '@/sparkaffiliates/services/program_products';
import { AffiliationStatus } from '@/types/affiliates';
import AffiliationDetails from '@/sparkaffiliates/views/drawers/AffiliationDetails.vue';
import RightDrawer from '@/components/RightDrawer.vue';
import MConfirmModal from '@/shared/components/MConfirmModal.vue';

const logging = debug('hs:affiliates');

export default {
  name: 'AffiliationsListTab',
  components: {
    AffiliationsList,
    ProgramFilter,
    EmptyList,
    hsLoading,
    RightDrawer,
    AffiliationDetails,
    MConfirmModal,
  },
  mixins: [LoadingMixin, ProgramFilterMixin, PaginationMixin],
  data() {
    return {
      isLoading: false,
      affiliationId: null,
      affiliations: [],
      programs: [],
      defaultSearchParams: {
        status: ['accepted', 'paused'],
      },
    };
  },
  watch: {
    'pagination.currentPage': function(newPage, _oldPage) {
      this.updatePage(newPage);
    },
  },
  created() {
    this.initiateProgramFilter(this.updateAffiliationsFromFilter);
    this.initiatePagination(this.updateAffiliationsFromPagination);
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    hasSelectedAffiliation() {
      return Boolean(this.affiliationId);
    },
    currentAffiliation() {
      const selectedAffiliation = this.affiliations.find(({ id }) => id === this.affiliationId) || {};

      return { ...selectedAffiliation, enabled: selectedAffiliation.status === AffiliationStatus.ACCEPTED };
    },
    affiliationCreatedAt() {
      return this.affiliationId ? dayjs(this.currentAffiliation.created_at).format('DD/MM/YYYY') : '';
    },
    countAffiliations() {
      return this.pagination.totalCount;
    },
    hasAffiliations() {
      return this.pagination.totalCount > 0;
    },
    drawerIsOpen() {
      return Boolean(this.affiliationId);
    },
  },
  methods: {
    showDeletionConfirmationModal() {
      this.$bvModal.show('confirm-modal');
    },
    closeConfirmationModal() {
      this.$bvModal.hide('confirm-modal');
    },
    async deleteDrawerAffiliation() {
      await affiliationsService.updateAffiliateAffiliationStatus(this.affiliationId, AffiliationStatus.REMOVED);
      this.closeDrawer();
      this.updateAffiliationsFromFilter();
    },
    async getActiveProducts(program) {
      try {
        if (_.isEmpty(program)) {
          return;
        }

        const { data: products } = await programProductsService.getAffiliateProgramProducts(program.id);

        const activeProducts = products.map(async product => {
          const productInfo = await productService.getName(product.sparkecommerce_product_id);

          return { name: productInfo.title, id: product.sparkecommerce_product_id };
        });

        return await Promise.all(activeProducts);
      } catch (e) {
        logging("failed to load the program's products", program.id, e);
        ToastHelper.dangerMessage('Ocorreu algum problema ao carregar produtos');
      }
    },
    goToProgramDetails({ id }) {
      this.$router.push({
        path: `/sparkaffiliates/affiliations/program/${id}`,
      });
    },
    closeDrawer() {
      this.affiliationId = null;
    },
    toggleActivation({ status, affiliation }) {
      try {
        let affiliationIndex = this.affiliations.findIndex(({ id }) => id === affiliation.id);
        this.affiliations[affiliationIndex].status = status ? AffiliationStatus.ACCEPTED : AffiliationStatus.PAUSED;

        affiliationsService.updateAffiliateAffiliationStatus(
          affiliation.id,
          status ? AffiliationStatus.ACCEPTED : AffiliationStatus.PAUSED
        );
        ToastHelper.successMessage(
          this.$t(`sparkaffiliates.programs-list.new-program.affiliates-panel.toast.${status}`)
        );
      } catch (e) {
        ToastHelper.dangerMessage(this.$t('sparkaffiliates.affiliates-list.toast.danger-message'));
      }
    },
    clearFilter() {
      this.clearProgramFilter();
      this.updateAffiliationsFromFilter();
    },
    onFailedLoading(e) {
      ToastHelper.dangerMessage(this.$t('Ocorreu algum problema ao listar suas afiliações'));
    },
    updateAffiliationsFromFilter() {
      this.loadAffiliations({
        ...this.searchParams,
        ...this.defaultSearchParams,
      }).catch(() => this.onFailedLoading());
    },
    updateAffiliationsFromPagination() {
      this.loadAffiliations({
        ...this.searchParams,
        ...this.defaultSearchParams,
        page: this.pagination.currentPage,
      }).catch(() => this.onFailedLoading());
    },
    loadAffiliations(params = {}) {
      return this.withLoading(async () => {
        const { affiliations, ...pagination } = await affiliationsService.getAffiliateAffiliations(params);
        this.affiliations = affiliations;
        this.pagination = pagination;
        logging('loaded %d affiliations', affiliations.length, affiliations);
      });
    },
    updateProgramsFilter() {
      this.programOptions = this.programs.map(program => ({
        id: program.id,
        title: program.name,
      }));
    },
    async loadPrograms(params = {}) {
      const { programs } = await programsService.getAffiliatePrograms(params);
      this.programs = programs;
      logging('loaded %d programs', programs.length, programs);
      this.updateProgramsFilter();
    },
    async fetchData() {
      this.loadAffiliations(this.defaultSearchParams)
        .then(() => this.loadPrograms({ items: 50 }))
        .catch(this.onFailedLoading);
    },
    showAffiliationInformation(a) {
      this.affiliationId = a.id;
    },
    goToAffiliationInvites() {},
  },
};
</script>
